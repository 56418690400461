import { changeLanguage } from 'i18next';
import { LangChange } from '../Common/lang-change';
import Footer from './footer/footer';
import { PcHeader } from './headers/pc-header';
import RecruitModal from './modal/recruit-modal';
import IntroduceSection from './sections/introduce-section';
import LocationSection from './sections/location-section';
import RecruitSection from './sections/recruit-section';
import ServiceSection from './sections/service-section';
import VisualSection from './sections/visual-section';
import { useEffect, useState } from 'react';
import MobileHeader from './headers/mobile-header';

const LANG_KEY = '__traport_web_lang__';

export default function Home() {
  const [englishChecked, setEnglishChecked] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem(LANG_KEY);
    if (lang) {
      langChange(lang === 'en');
    } else {
      langChange(lang === 'ko');
    }
    const a = checkIsMobile();
    setIsMobile(a);
  }, []);

  const langChange = (isEnglish: boolean) => {
    setEnglishChecked(isEnglish);
    switch (isEnglish) {
      case true:
        window.document.body.classList.add('en');
        window.document.body.classList.remove('ko');
        localStorage.setItem(LANG_KEY, 'en');
        break;
      case false:
        window.document.body.classList.add('ko');
        window.document.body.classList.remove('en');
        localStorage.setItem(LANG_KEY, 'ko');
        break;
      default:
        console.error('lang change error!');
    }
    return isEnglish ? changeLanguage('en') : changeLanguage('ko');
  };
  const checkIsMobile = () => {
    return window.innerWidth <= 768;
  };

  return (
    <>
      <div id='wrap'>
        <h1 className='header-logo'>TRAPORT</h1>
        {isMobile ? <MobileHeader></MobileHeader> : <PcHeader></PcHeader>}
        <LangChange
          englishChecked={englishChecked}
          onChange={langChange}
        ></LangChange>
        <main id='container'>
          <VisualSection></VisualSection>
          {/* <!-- ! introduce --> */}
          <IntroduceSection></IntroduceSection>
          {/* <!-- ! service --> */}
          <ServiceSection></ServiceSection>
          {/* <!-- ! recruit --> */}
          <RecruitSection></RecruitSection>
          {/* <!-- ! location --> */}
          <LocationSection></LocationSection>
        </main>

        {/* <!-- ! footer --> */}
        <Footer></Footer>
      </div>
      <RecruitModal></RecruitModal>
      <div className="mouse-circle"></div>
    </>
  );
}
