import { useTranslation } from "react-i18next";

export default function CtmInfo() {
  const { t } = useTranslation();
  
  return (
    <div className="ctm-info">
      <h4 className="section-title2" data-aos="fadeUp">CTM</h4>
      <h5 className="section-subtitle2" data-aos="fadeUp">{t('home.service.section.ctminfo.1')}</h5>
      <div className="ctm-detail-info">
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 21c-.168 0-.337-.011-.504-.033a16.339 16.339 0 0 1-2.471-8.217h13.437c.018-.249.038-.497.038-.75A10.5 10.5 0 1 0 12 22.5V21Zm8.962-9.75h-4.49a18.244 18.244 0 0 0-2.065-7.913 9.01 9.01 0 0 1 6.555 7.913Zm-8.458-8.217a16.34 16.34 0 0 1 2.471 8.217h-5.95a16.34 16.34 0 0 1 2.471-8.217 3.869 3.869 0 0 1 1.008 0Zm-2.912.304a18.246 18.246 0 0 0-2.064 7.913h-4.49a9.01 9.01 0 0 1 6.554-7.913ZM3.038 12.75h4.49c.08 2.76.786 5.466 2.064 7.913a9.01 9.01 0 0 1-6.554-7.913Z"></path>
              <path fillRule="evenodd" d="m18.75 18.75 3.75 1.5v-1.5l-3.75-1.875V15a.75.75 0 1 0-1.5 0v1.875L13.5 18.75v1.5l3.75-1.5v2.625l-1.5 1.125v.75L18 22.5l2.25.75v-.75l-1.5-1.125V18.75Z" clipRule="evenodd"></path>
            </svg>
          </p>
          <p>{t('home.service.section.ctminfo.2')}</p>
          <p>{t('home.service.section.ctminfo.3')}</p>
        </div>
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.125 11.25a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25Zm0-1.5a2.625 2.625 0 1 0 0 5.25 2.625 2.625 0 0 0 0-5.25Z"></path>
              <path d="M18.75 10.498h-6a1.5 1.5 0 0 0-1.5 1.5v4.5H3v-8.55l9-4.845 9.398 5.055.704-1.32-9.75-5.25a.75.75 0 0 0-.704 0l-9.75 5.25a.75.75 0 0 0-.398.66v15H3v-4.5h18v4.5h1.5v-8.25a3.75 3.75 0 0 0-3.75-3.75Zm-6 6v-4.5h6a2.25 2.25 0 0 1 2.25 2.25v2.25h-8.25Z"></path>
            </svg>
          </p>
          <p>{t('home.service.section.ctminfo.4')}</p>
          <p>{t('home.service.section.ctminfo.5')}</p>
        </div>
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.25 14.25h-4.5v1.5h4.5v-1.5Z"></path>
              <path d="m19.08 10.5-.953-3.412A1.507 1.507 0 0 0 16.683 6H7.316a1.507 1.507 0 0 0-1.443 1.088L4.9 10.5H3V12h1.5v5.25a1.502 1.502 0 0 0 1.5 1.5V21h1.5v-2.25h9V21H18v-2.25a1.502 1.502 0 0 0 1.5-1.5V12H21v-1.5h-1.92Zm-11.764-3h9.368l1.072 3.75H6.244L7.316 7.5ZM18 15.75v1.5H6v-1.5h1.5v-1.5H6v-1.5h12v1.5h-1.5v1.5H18Z"></path>
            </svg>
          </p>
          <p>{t('home.service.section.ctminfo.6')}</p>
          <p>{t('home.service.section.ctminfo.7')}</p>
        </div>
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 6V3.75a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0-.75.75V6a1.5 1.5 0 0 0-1.5 1.5V9h-3V7.5A1.5 1.5 0 0 0 9 6V3.75A.75.75 0 0 0 8.25 3h-4.5a.75.75 0 0 0-.75.75V6a1.5 1.5 0 0 0-1.5 1.5v9a1.5 1.5 0 0 0 .75 1.29v2.46A.75.75 0 0 0 3 21h6a.75.75 0 0 0 .75-.75v-2.46a1.5 1.5 0 0 0 .75-1.29V15h3v1.5a1.5 1.5 0 0 0 .75 1.29v2.46A.75.75 0 0 0 15 21h6a.75.75 0 0 0 .75-.75v-2.46a1.5 1.5 0 0 0 .75-1.29v-9A1.5 1.5 0 0 0 21 6ZM8.25 19.5h-4.5V18h4.5v1.5Zm.75-3H3v-9h1.5v-3h3v3H9v9Zm1.5-3v-3h3v3h-3Zm9.75 6h-4.5V18h4.5v1.5Zm.75-3h-6v-9h1.5v-3h3v3H21v9Z"></path>
            </svg>
          </p>
          <p>{t('home.service.section.ctminfo.8')}</p>
          <p>{t('home.service.section.ctminfo.9')}</p>
        </div>
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 15.75H9a1.5 1.5 0 0 1-1.5-1.5v-1.5a1.5 1.5 0 0 1 1.5-1.5h6a1.5 1.5 0 0 1 1.5 1.5v1.5a1.5 1.5 0 0 1-1.5 1.5Zm-6-3v1.5h6v-1.5H9Z"></path>
              <path d="M21 3H3a1.5 1.5 0 0 0-1.5 1.5v3A1.5 1.5 0 0 0 3 9v12a1.5 1.5 0 0 0 1.5 1.5h15A1.5 1.5 0 0 0 21 21V9a1.5 1.5 0 0 0 1.5-1.5v-3A1.5 1.5 0 0 0 21 3Zm-1.5 18h-15V9h15v12ZM21 7.5H3v-3h18v3Z"></path>
            </svg>
          </p>
          <p>{t('home.service.section.ctminfo.10')}</p>
          <p>{t('home.service.section.ctminfo.11')}</p>
        </div>
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.25 12h-1.5v1.5h1.5V12Z"></path>
              <path d="M12.75 12h-6v1.5h6V12Z"></path>
              <path d="M17.25 9h-1.5v1.5h1.5V9Z"></path>
              <path d="M12.75 9h-6v1.5h6V9Z"></path>
              <path d="M17.25 6H6.75v1.5h10.5V6Z"></path>
              <path d="M18.75 1.5H5.25A1.502 1.502 0 0 0 3.75 3v18.75a.75.75 0 0 0 .75.75h.75a.748.748 0 0 0 .6-.3L7.5 20l1.65 2.2a.776.776 0 0 0 1.2 0L12 20l1.65 2.2a.775.775 0 0 0 1.2 0L16.5 20l1.65 2.2a.749.749 0 0 0 .6.3h.75a.75.75 0 0 0 .75-.75V3a1.502 1.502 0 0 0-1.5-1.5Zm0 19-1.65-2.2a.775.775 0 0 0-1.2 0l-1.65 2.2-1.65-2.2a.775.775 0 0 0-1.2 0l-1.65 2.2-1.65-2.2a.776.776 0 0 0-1.2 0l-1.65 2.2V3h13.5v17.5Z"></path>
            </svg>
          </p>
          <p>{t('home.service.section.ctminfo.12')}</p>
          <p>{t('home.service.section.ctminfo.13')}</p>
        </div>
      </div>
      <ul className="detail-text">
        <li data-aos="fade-up">
          <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 17.998 3 11.248l1.06-1.06 5.69 5.688L19.94 5.688 21 6.748 9.75 17.998Z"></path>
          </svg>
          <span><strong>Compliance Monitoring</strong>{t('home.service.section.ctminfo.14')}</span>
        </li>
        <li data-aos="fade-up">
          <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 17.998 3 11.248l1.06-1.06 5.69 5.688L19.94 5.688 21 6.748 9.75 17.998Z"></path>
          </svg>
          <span><strong>Missed Saving</strong>{t('home.service.section.ctminfo.15')}</span>
        </li>
        <li data-aos="fade-up">
          <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 17.998 3 11.248l1.06-1.06 5.69 5.688L19.94 5.688 21 6.748 9.75 17.998Z"></path>
          </svg>
          <span><strong>{t('home.service.section.ctminfo.16')}</strong>{t('home.service.section.ctminfo.17')}</span>
        </li>
      </ul>
    </div>
  );
}
