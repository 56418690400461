import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer id="footer">
      <div className="inside">
        <ul className="company-info">
          <li>
            <span className="footer-logo">TRAPORT</span>
          </li>
          <li>
            <span>{t("home.footer.1")}</span>
            <span>{t("home.footer.2")}</span>
            <span>{t("home.footer.3")}</span>
            <span><a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2118819164" target="_blank" className="link" rel="noopener noreferrer">{t("home.footer.4")}</a></span>
          </li>
          <li>
            <span>{t("home.footer.5")}</span>
          </li>
          <li>
            <span>{t("home.footer.7")}&nbsp;:&nbsp;<address>{t("home.footer.6")}</address></span>
            <span>{t("home.footer.8")}&nbsp;:&nbsp;<a href="tel:0269679918">02-6967-9918</a></span>
          </li>
          <li><span className="copyright">Copyright &copy; TRAPORT, All Rights Reserved</span></li>
        </ul>
      </div>
    </footer>
  );
}
