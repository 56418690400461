import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function RecruitModal() {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [portfolioLink, setPortfolioLink] = useState('');
  const [education, setEducation] = useState('');
  const [career, setCareer] = useState('');
  const [position, setPosition] = useState('');
  const [agree, setAgree] = useState(false);

  const checkAgree = (checked: boolean) => {
    setAgree(checked);
  };

  const openMailto = () => {
    // eslint-disable-next-line
    const emailValidation = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

    if (emailValidation === false) {
      alert(t('modal.recruit.38'));
      return;
    }

    if (
      name === '' ||
      birthday === '' ||
      gender === '' ||
      phone === '' ||
      portfolioLink === '' ||
      education === '' ||
      career === '' ||
      position === ''
    ) {
      alert(t('modal.recruit.36'));
      return;
    }

    if (!agree) {
      alert(t('modal.recruit.37'));
      return;
    }

    const subject = `[${position}(${career})] ${name}`;
    const body = `
      %0D%0A
      ------------Personal Info----------%0D%0A
        name: ${name}%0D%0A
        position: ${position}%0D%0A
        career: ${career}%0D%0A
        portfolioLink: ${portfolioLink}%0D%0A
        education: ${education}%0D%0A
        birthday: ${birthday}%0D%0A
        gender: ${gender}%0D%0A
        email: ${email}%0D%0A
        phone: ${phone}%0D%0A
      -----------------------------------%0D%0A
    `;

    window.open(`mailto:service@traport.com?subject=${subject}&body=${body}`);
  };

  return (
    <div className='modal'>
      <div className='modal-contents'>
        <form action=''>
          <div className='modal-header'>
            <h1>{t('modal.recruit.1')}</h1>
            <button
              type='button'
              className='btn-modal-close'
            >
              <svg
                className='svg-icon'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M18 7.05 16.95 6 12 10.95 7.05 6 6 7.05 10.95 12 6 16.95 7.05 18 12 13.05 16.95 18 18 16.95 13.05 12 18 7.05Z'></path>
              </svg>
            </button>
          </div>
          <div className='modal-body'>
            <ul className='apply-form'>
              {/* 이름 */}
              <li>
                <dl>
                  <dt>
                    <sup>{t('modal.recruit.2')}</sup>
                  </dt>
                  <dd>
                    <div className='input'>
                      <input
                        type='text'
                        placeholder={t('modal.recruit.31')}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </dd>
                </dl>
              </li>
              {/* 생년월일 */}
              <li>
                <dl>
                  <dt>
                    <sup>{t('modal.recruit.3')}</sup>
                  </dt>
                  <dd>
                    <div className='input'>
                      <input
                        type='number'
                        placeholder={t('modal.recruit.32')}
                        maxLength={8}
                        onChange={(e) => setBirthday(e.target.value)}
                      />
                    </div>
                  </dd>
                </dl>
              </li>
              {/* 성별 */}
              <li>
                <dl>
                  <dt>
                    <sup>{t('modal.recruit.4')}</sup>
                  </dt>
                  <dd>
                    <div className='select'>
                      <select
                        name='gender'
                        id='gender'
                        onChange={(e) => setGender(e.target.value)}
                        required
                        value={gender}
                      >
                        <option
                          value=''
                          disabled
                        >
                          {t('modal.recruit.5')}
                        </option>
                        <option value='남자(M)'>{t('modal.recruit.6')}</option>
                        <option value='여자(F)'>{t('modal.recruit.7')}</option>
                      </select>
                    </div>
                  </dd>
                </dl>
              </li>
              {/* 연락처 */}
              <li>
                <dl>
                  <dt>
                    <sup>{t('modal.recruit.10')}</sup>
                  </dt>
                  <dd>
                    <div className='input'>
                      <input
                        type='tel'
                        placeholder={t('modal.recruit.11')}
                        maxLength={11}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </dd>
                </dl>
              </li>
              {/* 이메일 주소 */}
              <li>
                <dl>
                  <dt>
                    <sup>{t('modal.recruit.8')}</sup>
                  </dt>
                  <dd>
                    <div className='input'>
                      <input
                        type='email'
                        placeholder={t('modal.recruit.9')}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </dd>
                </dl>
              </li>
              {/* 포트폴리오 */}
              <li>
                <dl>
                  <dt>
                    <sup>{t('modal.recruit.12')}</sup>
                  </dt>
                  <dd>
                    <div className='input'>
                      <input
                        type='url'
                        placeholder={t('modal.recruit.13')}
                        onChange={(e) => setPortfolioLink(e.target.value)}
                      />
                    </div>
                  </dd>
                </dl>
              </li>
              {/* 최종학력 */}
              <li>
                <dl>
                  <dt>
                    <sup>{t('modal.recruit.14')}</sup>
                  </dt>
                  <dd>
                    <div className='select'>
                      <select
                        name='education'
                        id='education'
                        required
                        onChange={(e) => setEducation(e.target.value)}
                        value={education}
                      >
                        <option
                          value=''
                          disabled
                        >
                          {t('modal.recruit.15')}
                        </option>
                        <option value='고등학교 졸업'>{t('modal.recruit.16')}</option>
                        <option value='대학교 재학중'>{t('modal.recruit.17')}</option>
                        <option value='대학교 졸업(전문 학사)'>{t('modal.recruit.18')}</option>
                        <option value='대학교 졸업(학사 이상)'>{t('modal.recruit.19')}</option>
                      </select>
                    </div>
                  </dd>
                </dl>
              </li>
              {/* 경력기간 */}
              <li>
                <dl>
                  <dt>
                    <sup>{t('modal.recruit.33')}</sup>
                  </dt>
                  <dd>
                    <div className='select'>
                      <select
                        name='career'
                        id='career'
                        required
                        onChange={(e) => setCareer(e.target.value)}
                        value={career}
                      >
                        <option
                          value=''
                          disabled
                        >
                          {t('modal.recruit.20')}
                        </option>
                        <option value='1년 미만'>1{t('modal.recruit.lessyear')}</option>
                        <option value='1년 이상 3년 미만'>
                          1{t('modal.recruit.moreyear')} 3{t('modal.recruit.lessyear')}
                        </option>
                        <option value='3년 이상 5년 미만'>
                          3{t('modal.recruit.moreyear')} 5{t('modal.recruit.lessyear')}
                        </option>
                        <option value='5년 이상 8년 미만'>
                          5{t('modal.recruit.moreyear')} 8{t('modal.recruit.lessyear')}
                        </option>
                        <option value='8년 이상'>8{t('modal.recruit.moreyear')}</option>
                      </select>
                    </div>
                  </dd>
                </dl>
              </li>
              {/* 지원 포지션 */}
              <li>
                <dl>
                  <dt>
                    <sup>{t('modal.recruit.23')}</sup>
                  </dt>
                  <dd>
                    <div className='select'>
                      <select
                        name='position'
                        id='position'
                        required
                        onChange={(e) => setPosition(e.target.value)}
                        value={position}
                      >
                        <option
                          value=''
                          disabled
                        >
                          {t('modal.recruit.24')}
                        </option>
                        <option value='Frontend'>{t('modal.recruit.25')}</option>
                        <option value='Backend'>{t('modal.recruit.26')}</option>
                      </select>
                    </div>
                  </dd>
                </dl>
              </li>
            </ul>
            <dl className='recruit-agree'>
              <dt>
                <label className='checkbox'>
                  <input
                    type='checkbox'
                    className='control-input'
                    onChange={(e) => checkAgree(e.target.checked)}
                  />
                  <span className='control-text'>
                    <span>
                      <em>{t('modal.recruit.28')}&nbsp;</em>
                      {t('modal.recruit.29')}
                    </span>
                  </span>
                </label>
              </dt>
              <dd className='privacy-detail'>
                {t('recruit.notice.1')}
                <br />
                {t('recruit.notice.2')}
                <br />
                {t('recruit.notice.3')}
                <br />
                {t('recruit.notice.4')}
                <br />
                {t('recruit.notice.5')}
                <br />
                {t('recruit.notice.6')}
                <br />
              </dd>
              <dd className='noti'>{t('modal.recruit.34')}</dd>
            </dl>
          </div>
          <div className='modal-footer'>
            <button
              type='submit'
              className='btn-apply'
              onClick={() => openMailto()}
            >
              {t('modal.recruit.35')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
