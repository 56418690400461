import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function VisualSection() {
  const { i18n } = useTranslation();
  const [isEnglish, setIsEnglish] = useState(i18n.language === 'en');

  useEffect(() => {
    setIsEnglish(i18n.language === 'en');
  }, [i18n.language]);
  
  useEffect(() => {
    const englishTitleElement = document.querySelector('.title.en');
    const koreanTitleElement = document.querySelector('.title.ko');
  
    setTimeout(function () {
      if (isEnglish) {
        englishTitleElement?.classList.add('animate-start');
      } else {
        koreanTitleElement?.classList.add('animate-start');
      }
    }, 1500);
  }, [isEnglish])

  return (
    <section id="visual">
      {
        isEnglish ?
          <div className="title en">
            <p className="visual-text1">
              <span>Leading the travel industry</span>
            </p>
            <p className="visual-text1">
              <span>with <strong>Technology</strong></span>
            </p>
          </div>
          :
          <div className="title ko">
            <p className="visual-text1">
              <span><strong>트래포트</strong>는</span>
            </p>
            <p className="visual-text1">
              <span>여행산업에 필요한 IT 서비스를</span>
            </p>
            <p className="visual-text1">
              <span>고객사에 제공합니다.</span>
            </p>
          </div>
      }
      <ul className="visual-list">
        <li className="visual-item"></li>
        <li className="visual-item"></li>
        <li className="visual-item"></li>
        <li className="visual-item"></li>
        <li className="visual-item"></li>
        <li className="visual-item"></li>
      </ul>
      <div className="dim"></div>
      <div className="scroll-animation"></div>
    </section>
  );
}