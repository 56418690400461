export default function MobileHeader() {
  return (
    <header id='mo-header'>
      <nav className='main-nav'>
        <div className='nav-item'>
          <a href='#introduce'>회사소개</a>
        </div>
        <div className='nav-item'>
          <a href='#service'>사업소개</a>
        </div>
        <div className='nav-item'>
          <a href='#recruit'>채용안내</a>
        </div>
        <div className='nav-item'>
          <a href='#location'>오시는길</a>
        </div>
      </nav>
    </header>
  );
}
