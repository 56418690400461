import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const resources = {
  en: {
    translation: {
      'home.header.introduce.company': 'ABOUT US',
      'home.header.introduce.business': 'BUSINESS',
      'home.header.introduce.hire': 'RECRUIT',
      'home.header.introduce.place': 'LOCATION',

      'home.introduce.section.company': 'ABOUT US',
      'home.introduce.section.introduce.1': 'Travel Tech Company which is changing the travel industry',
      'home.introduce.section.introduce.2': 'Dynamic Package Service',
      'home.introduce.section.introduce.3':
        'We created the concept of Dynamic Package for the first time in the domestic OTA industry in 2010.',
      'home.introduce.section.introduce.4': 'Meta Booking Platform',
      'home.introduce.section.introduce.5':
        'It has become the standard for most e-commerce travel meta-services since 2015.',
      'home.introduce.section.introduce.6': 'CTM Platform',
      'home.introduce.section.introduce.7':
        'Business travelers who work in Samsung and LG are using the platform that we supplied.',

      'home.introduce.section.history.1': 'Footsteps for travel industry',
      'home.introduce.section.history.2': 'Supplied BTMS to Hana Tour Business',
      'home.introduce.section.history.3': 'Supplied CMS system to OHMYHOTEL',
      'home.introduce.section.history.4': 'Supplied OTA System to Yellow Balloon Tour',
      'home.introduce.section.history.5': 'Launched Traport BTMS solution',
      'home.introduce.section.history.6': 'Supplied BTMS to Red Cap Tour',
      'home.introduce.section.history.7': "Launched Samsung Electronics' Business Trip Management System (BTMS)",
      'home.introduce.section.history.8':
        "Launched the world's first itinerary commerce service Travel How 'Where to Go'",
      'home.introduce.section.history.9': "Launched Travel How 'Ticket/Tour' category",
      'home.introduce.section.history.10': 'Launched Travel How overseas Hotel and traveler Insurance Service',
      'home.introduce.section.history.11': 'Serviced Travel How Service to Shinhan All That Shopping',
      'home.introduce.section.history.12': 'Partnership with Easywell Fair for overseas airlines and overseas hotel',
      'home.introduce.section.history.13': "Launched Domestic ticket meta-booking platform 'Travel How'",

      'home.introduce.section.history.14':
        'Launched domestic accommodation/overseas hotel mobile service to Auction Travel',
      'home.introduce.section.history.15': 'Amadeus Air reservation engine migration',
      'home.introduce.section.history.16':
        'Supplied Hotel Shilla (Samsung Group) integrated business trip management solution (CTM)',
      'home.introduce.section.history.17':
        'Launched land pack reservation and management system for the first time in Korea',
      'home.introduce.section.history.18': 'Launched airtel a real-time reservation system for the first time in Korea',
      'home.introduce.section.history.19':
        'Launched rental car reservation service (About 40 rental car companies worldwide)',
      'home.introduce.section.history.20':
        'Launched Eurail Pass, European railway ticket, Amtrak, JR Pass real-time reservation system',
      'home.introduce.section.history.21':
        'Launched comparing prices of travel products and a real-time reservation system for the first time in Korea travel agency',
      'home.introduce.section.history.22': 'Launched and operated Auction Travel site (tour.auction.co.kr)',
      'home.introduce.section.history.23': 'Launched Traport airlines and hotel real-time reservation application',
      'home.introduce.section.history.24': 'Launched b2b.traport.com for B2B partners',
      'home.introduce.section.history.25': 'Registered IATA BSP agent',
      'home.introduce.section.history.26': 'Supplied flight reservation system to BIE Airlines/Samsung Card',
      'home.introduce.section.history.27': 'Supplied new booking engine (air, hotel) to Topas',
      'home.introduce.section.history.28': 'Supplied wholesale filigt reservation system to Jau Tour',
      'home.introduce.section.history.29':
        'Launched www.traport.com – Dynamic package solution for the first time in Korea',
      'home.introduce.section.history.30': 'Supplied BTMS (Business Travel Management System) to SK Tourvis',
      'home.introduce.section.history.31': 'Supplied hotel reservation web service to Topas',
      'home.introduce.section.history.32': 'Supplied hotel reservation system (myhanahotel.com) to Hana Tour',

      'home.service.section.business': 'BUSINESS',

      'home.service.section.ota.1': 'The best solution for maximizing OTA revenue',
      'home.service.section.ota.2': 'The customer book a hotel through the site',
      'home.service.section.ota.3':
        "Traport's technology finds other suppliers who offer lower rates on the same conditions before supplier's' cancellation deadline",
      'home.service.section.ota.4':
        'Create new reservation with newly found hotel and cancel existing customer reservation',
      'home.service.section.ota.5':
        "Maximize OTA revenue by finding suppliers that are cheaper than customers' booking",
      'home.service.section.ota.6':
        'Hotel code mapping and room mapping which are the core technologies required for rebooking. We developed through machine learning after 3 years of research and development and trial and error.',
      'home.service.section.ota.7': "OTA doesn't require new development or financial investment.",
      'home.service.section.ota.8':
        "It's a revenue share process so you don't have to pay for it if we doesn't find a cheaper price.",

      'home.service.section.single.api.1': 'Provide all hotel services for OTA',
      'home.service.section.single.api.2': 'TRAPORT',
      'home.service.section.single.api.3': 'Hotel Content',
      'home.service.section.single.api.4': 'Hotel Mapping',
      'home.service.section.single.api.5': 'Client',
      'home.service.section.single.api.6':
        'It provides complex OTA tasks such as provider interface, CMS introduction, and Local DMC Extranet operation in one standard API.',
      'home.service.section.single.api.7': 'It can be reduce development and maintenance costs.',
      'home.service.section.single.api.8': 'It provides hotel content, hotel mapping, and VCC functions.',

      'home.service.section.ctminfo.1': 'Business Travel Platform Helps Reduce Business Travel Costs',
      'home.service.section.ctminfo.2': 'Real-time bookings for 700 airlines',
      'home.service.section.ctminfo.3': '',
      'home.service.section.ctminfo.4': 'Real-time bookings for 300,000 hotels',
      'home.service.section.ctminfo.5': '',
      'home.service.section.ctminfo.6': 'Real-time bookings for 400,000 rental cars',
      'home.service.section.ctminfo.7': '',
      'home.service.section.ctminfo.8': 'Monitoring business trip regulations',
      'home.service.section.ctminfo.9': '',
      'home.service.section.ctminfo.10': 'Collection of reasons for exceeding business trip  regulations',
      'home.service.section.ctminfo.11': '',
      'home.service.section.ctminfo.12': 'Reporting to Business trip Manager',
      'home.service.section.ctminfo.13': '',
      'home.service.section.ctminfo.14':
        ' - It monitors the suitability/violation of travel regulations to induce reservations of products that are suitable for travel regulations.',
      'home.service.section.ctminfo.15':
        ' - It meets the business trip regulations, but if there is a lower fare, we will induce you to purchase cheaper fare products.',
      'home.service.section.ctminfo.16': 'Analysis of managment data',
      'home.service.section.ctminfo.17':
        ' - It provides reports and excel statistics, so it can be used as various data by companies.',

      'home.service.section.location.1': 'LOCATION',
      'home.service.section.location.2': 'Welcome to your visit.',
      'home.service.section.location.3': '25, Insadong 5-gil, Jongno-gu, Seoul , Korea',
      'home.service.section.location.4': '5 minutes walk from Exit 8 of Jonggak Station on subway Line 1',
      'home.service.section.location.5': '5 minutes walk from "Jongno 2-ga" bus stop',

      'home.recruit.1': 'RECRUIT',
      'home.recruit.2': "We are finding people to share the company's growth and future.",
      'home.recruit.3': 'A person who values cooperation and relationships with members',
      'home.recruit.4': 'A person of curiosity and enthusiasm for his work',
      'home.recruit.5': 'A person who want to be the best by building expertise in a field',
      'home.recruit.6': 'This is how we work!',
      'home.recruit.7': 'Flexible working time system',
      'home.recruit.8': 'You can start the work between 8:30 a.m. and 9:30 a.m.',
      'home.recruit.9': 'Only 8 hours working a day!',
      'home.recruit.10': 'Support for the latest equipment',
      'home.recruit.11': 'We offer Apple iMac and dual monitors for developer.',
      'home.recruit.12': '',
      'home.recruit.13': 'Use annual leave freely',
      'home.recruit.14': 'When you have an emergency or want to go somewhere,',
      'home.recruit.15': 'you can use an annual leave freely.',
      'home.recruit.16': 'No more unnecessary company dinner',
      'home.recruit.17': 'We have company dinner whenever the members want.',
      'home.recruit.18': 'No one is forcing you to drink!',
      'home.recruit.19': 'Free dress for work',
      'home.recruit.20': "We don't have any restrictions on your clothes.",
      'home.recruit.21': '',
      'home.recruit.22': 'We work together for the growth of our members.',
      'home.recruit.23': 'Support for book, training, and seminar costs',
      'home.recruit.24': '',
      'home.recruit.25': '',
      'home.recruit.26': 'Support for overtime taxi fee',
      'home.recruit.27': '',
      'home.recruit.28': '',
      'home.recruit.29': 'Support for working from home',
      'home.recruit.30': '',
      'home.recruit.31': '',
      'home.recruit.32': 'Birthday gift + Early leave',
      'home.recruit.33': '',
      'home.recruit.34': '',
      'home.recruit.35': 'Gift for traditional Korean holiday',
      'home.recruit.36': '',
      'home.recruit.37': '',
      'home.recruit.38': 'Support for non-marriage declaration member',
      'home.recruit.39': '',
      'home.recruit.40': '',
      'home.recruit.41': 'Support for family events',
      'home.recruit.42': '',
      'home.recruit.43': '',
      'home.recruit.44': 'Overseas workshop',
      'home.recruit.45': '',
      'home.recruit.46': '',
      'home.recruit.47': 'Recruiting now',
      'home.recruit.48': 'We are waiting for you!',
      'home.recruit.49': 'New',
      'home.recruit.50': 'Software Developer (Frontend) - 2 people',
      'home.recruit.51': 'Specialist who are using HTML/CSS/JavaScript (TypeScript)',
      'home.recruit.52':
        'Experienced in SPA, SSR development using Angular Framework is preferred, Experienced using React is preferred.',
      'home.recruit.53': 'Experienced in client development using REST API is preferred.',
      'home.recruit.54': 'Specialist who are using the distributed version control system (Git)',
      'home.recruit.55': 'New',
      'home.recruit.56': 'Software Developer (Backend) - 2 people',
      'home.recruit.57': 'Experienced Spring Boot development or build is preferred.',
      'home.recruit.58': 'Experienced building a backend using Spring Webflux + Java and Kotlin is preferred.',
      'home.recruit.59': 'Experienced building Python + Django Web Framework is preferred.',
      'home.recruit.60': 'Experienced building AWS/DevOps is preferred.',
      'home.recruit.61': 'We are looking forward to seeing you at Traport!',
      'home.recruit.62': 'Apply Here',

      'home.footer.1': 'Traport.com Inc.',
      'home.footer.2': 'Representative name : AHN KYOUNG YOUL',
      'home.footer.3': 'Business number : 211-88-19164',
      'home.footer.4': 'Check business information',
      'home.footer.5': 'Personal information management manager : KIM YOUNG KWON',
      'home.footer.6': '25, Insadong 5-gil, Jongno-gu, Seoul, Korea',
      'home.footer.7': 'Address',
      'home.footer.8': 'TEL',
      'home.footer.9': 'Copyright © TRAPORT, All Rights Reserved',

      'modal.recruit.1': 'Apply form',
      'modal.recruit.2': 'Name',
      'modal.recruit.3': 'Date of Birth',
      'modal.recruit.4': 'Gender',
      'modal.recruit.5': 'Select',
      'modal.recruit.6': 'Male',
      'modal.recruit.7': 'Female',
      'modal.recruit.8': 'Email',
      'modal.recruit.9': 'Email',
      'modal.recruit.10': 'Phone number',
      'modal.recruit.11': 'Phone number(Numbers only)',
      'modal.recruit.12': 'Portfolio',
      'modal.recruit.13': 'Portfolio url address',
      'modal.recruit.14': 'Education',
      'modal.recruit.15': 'Select',
      'modal.recruit.16': 'High school graduated',
      'modal.recruit.17': 'In university',
      'modal.recruit.18': "Associate's degree",
      'modal.recruit.19': "Bachelor's degree",
      'modal.recruit.20': 'Select',
      'modal.recruit.lessyear': 'less than',
      'modal.recruit.moreyear': 'more than',
      'modal.recruit.23': 'Position',
      'modal.recruit.24': 'Select',
      'modal.recruit.25': 'Frontend',
      'modal.recruit.26': 'Backend',
      'modal.recruit.27': 'Required',
      'modal.recruit.28': 'Collection and use of personal information',
      'modal.recruit.29': 'agreement',
      'modal.recruit.30': 'See More',
      'modal.recruit.31': 'Name',
      'modal.recruit.32': 'Date of birth (Numbers only)',
      'modal.recruit.33': 'Career',
      'modal.recruit.34':
        'The information received will not be used for any purpose other than the recruitment process.',
      'modal.recruit.35': 'Apply',
      'modal.recruit.36': 'Please fill in all the blanks',
      'modal.recruit.37': 'Please agree to the item',
      'modal.recruit.38': 'Email format is wrong',

      'recruit.notice.1': '1. Purpose of collection',
      'recruit.notice.2': '- Recruitment application',
      'recruit.notice.3': '2. Collection items',
      'recruit.notice.4':
        '- Name, Date of birth, Gender, Email address, Phone number, Portfolio, Education, Career, Position',
      'recruit.notice.5': '3. Period of retentation',
      'recruit.notice.6': '- Until the end of recruitment',
    },
  },
  ko: {
    translation: {
      'home.header.introduce.company': '회사소개',
      'home.header.introduce.business': '사업소개',
      'home.header.introduce.hire': '채용안내',
      'home.header.introduce.place': '오시는길',

      'home.introduce.section.company': '회사소개',
      'home.introduce.section.introduce.1': '여행산업을 변화시키고 있는 Travel Tech Company',
      'home.introduce.section.introduce.2': '국내 최초 Dynamic Package Service',
      'home.introduce.section.introduce.3': '2010년, 국내 OTA 업계 최초로 Dynamic Package의 개념을 만들었습니다.',
      'home.introduce.section.introduce.4': '국내 최초 Meta Booking Platform',
      'home.introduce.section.introduce.5':
        '2015년 처음 선보인 이후, 대부분의 이커머스 여행 메타 서비스의 표준이 되었습니다.',
      'home.introduce.section.introduce.6': '국내 최초 출장 Platform',
      'home.introduce.section.introduce.7':
        '삼성, LG 등 그룹사의 출장자들은 트래포트가 만든 출장 플랫폼을 사용하고 있습니다.',

      'home.introduce.section.history.1': '바쁘게 달려온 우리의 발자취입니다.',
      'home.introduce.section.history.2': '하나투어 비즈니스 BTMS 시스템 공급',
      'home.introduce.section.history.3': '오마이호텔 CMS 시스템 공급',
      'home.introduce.section.history.4': '노랑풍선 OTA 시스템 공급',
      'home.introduce.section.history.5': 'BTMS 출장관리자 시스템 오픈',
      'home.introduce.section.history.6': '레드캡 BTMS 시스템 공급',
      'home.introduce.section.history.7': '삼성전자 출장관리시스템 오픈',
      'home.introduce.section.history.8': "세계 최초 일정표 커머스 서비스 트래블하우 '어디갈까' 오픈",
      'home.introduce.section.history.9': "트래블하우 '티켓/투어' 카테고리 오픈",
      'home.introduce.section.history.10': '트래블하우 해외호텔, 여행자보험 서비스 오픈',
      'home.introduce.section.history.11': '신한올댓쇼핑에 트래블하우 서비스',
      'home.introduce.section.history.12': '이지웰페어 해외항공 및 해외호텔 제휴',
      'home.introduce.section.history.13': "국내 항공권 메타부킹 플랫폼 '트래블하우' 오픈",

      'home.introduce.section.history.14': '옥션여행 국내숙박/해외호텔 모바일 오픈',
      'home.introduce.section.history.15': '아마데우스 항공예약엔진 마이그레이션',
      'home.introduce.section.history.16': '호텔신라(삼성그룹) 통합 출장관리솔루션 공급(CTM)',
      'home.introduce.section.history.17': '국내 최초 랜드팩 예약 및 관리시스템 오픈',
      'home.introduce.section.history.18': '국내 최초 항공사 에어텔 실시간 예약시스템 오픈',
      'home.introduce.section.history.19': '렌터카 예약서비스 오픈 (전세계 40여 개 렌터카 업체 서비스)',
      'home.introduce.section.history.20': '유레일패스, 유럽철도 구간권, Amtrak, JR패스 실시간 예약시스템 오픈',
      'home.introduce.section.history.21': '국내 최초 여행사 여행상품 가격비교 및 실시간 예약시스템 오픈',
      'home.introduce.section.history.22': '옥션 여행 사이트(tour.auction.co.kr) 오픈 및 운영',
      'home.introduce.section.history.23': '트래포트 항공, 호텔 실시간 예약 어플 오픈',
      'home.introduce.section.history.24': 'b2b.traport.com 호텔판매 제휴사용 B2B 사이트 오픈',
      'home.introduce.section.history.25': '국제항공운송협회(IATA)인가 BSP 대리점 등록',
      'home.introduce.section.history.26': 'BIE항공 / 삼성카드 항공예약시스템 공급',
      'home.introduce.section.history.27': '토파스여행정보㈜ New Booking Engine(항공, 호텔) 공급',
      'home.introduce.section.history.28': '자유투어 항공홀세일 예약시스템 공급',
      'home.introduce.section.history.29': 'www.traport.com 오픈 - 국내 최초 Dynamic Package Solution',
      'home.introduce.section.history.30': 'SK투어비스㈜ BTMS(Business Travel Management System) 공급',
      'home.introduce.section.history.31': '토파스여행정보㈜ 호텔예약 웹 서비스(Web Service) 공급',
      'home.introduce.section.history.32': '하나투어㈜ 호텔예약시스템 myhanahotel.com 공급',

      'home.service.section.business': '사업소개',

      'home.service.section.ota.1': 'OTA 수익 극대화를 위한 최고의 솔루션',
      'home.service.section.ota.2': '고객이 사이트를 통해 호텔 예약 완료',
      'home.service.section.ota.3':
        '예약된 호텔공급사 취소마감시한까지 같은 조건에 더 저렴한 요금을 제공하는 공급자를 트래포트의 기술을 통해 찾음',
      'home.service.section.ota.4': '새로 찾은 호텔 공급사로 신규예약을 만들고 기존 고객 예약은 취소',
      'home.service.section.ota.5': '고객의 예약보다 더 저렴한 공급사를 찾아 OTA 수익을 극대화 시킴',
      'home.service.section.ota.6':
        'Rebooking 에 필요한 핵심 기술인 호텔 코드 매핑과 객실 매핑을 3년 동안의 연구개발과 시행착오를 거쳐 머신 러닝을 통해 개발하였습니다.',
      'home.service.section.ota.7': 'OTA는 신규로 개발하거나, 재무적 투자를 할 필요가 없습니다.',
      'home.service.section.ota.8':
        '수익쉐어 방식으로 진행되며 트래포트가 더 싼 요금을 찾지 못하면 지불을 비용할 필요가 없습니다.',

      'home.service.section.single.api.1': 'OTA를 위한 모든 호텔 서비스 제공',
      'home.service.section.single.api.2': '트래포트',
      'home.service.section.single.api.3': '호텔 컨텐츠',
      'home.service.section.single.api.4': '호텔 맵핑',
      'home.service.section.single.api.5': '고객사',
      'home.service.section.single.api.6':
        '공급자 Interface, CMS 도입, Local DMC Extranet 운영 등 복잡한 OTA 작업을 1개의 표준 API 로 제공합니다.',
      'home.service.section.single.api.7': '개발 및 유지보수에 들어가는 비용을 줄일 수 있습니다.',
      'home.service.section.single.api.8': '호텔 컨텐츠, 호텔 맵핑, VCC 기능을 제공합니다.',

      'home.service.section.ctminfo.1': '기업 출장 경비 절감에 효과적인 출장플랫폼',
      'home.service.section.ctminfo.2': '700여개 항공사 실시간 예약',
      'home.service.section.ctminfo.3': '전세계 700여개 항공사 실시간 예약 가능',
      'home.service.section.ctminfo.4': '30만개 호텔 실시간 예약',
      'home.service.section.ctminfo.5': '전세계 30만개 호텔 실시간 예약 가능',
      'home.service.section.ctminfo.6': '40만개 렌터카 실시간 예약',
      'home.service.section.ctminfo.7': '전세계 40만개 렌터카 실시간 예약 가능',
      'home.service.section.ctminfo.8': '출장규정 모니터링',
      'home.service.section.ctminfo.9': '부서, 직급, 지역 등에 따른 출장규정 설정',
      'home.service.section.ctminfo.10': '출장규정 초과사유 수집',
      'home.service.section.ctminfo.11': '초과 경비 수집 및 리포트',
      'home.service.section.ctminfo.12': '출장관리자 리포팅',
      'home.service.section.ctminfo.13': '상세한 출장 경비 리포팅 제공',
      'home.service.section.ctminfo.14': ' - 출장 규정 적합/ 위반여부를 모니터링하여 적합 규정 상품 예약 유도합니다.',
      'home.service.section.ctminfo.15': ' - 출장 규정 적합/ 위반여부를 모니터링하여 적합 규정 상품 예약 유도합니다.',
      'home.service.section.ctminfo.16': '출장관리자 데이터 분석',
      'home.service.section.ctminfo.17':
        ' - 각종 리포트 및 Excel 통계 자료를 제공하여 기업에서 다양한 자료로 활용 가능합니다.',

      'home.service.section.location.1': '오시는 길',
      'home.service.section.location.2': '여러분의 방문을 환영합니다.',
      'home.service.section.location.3': '서울 종로구 인사동5길 25 하나로빌딩 809호',
      'home.service.section.location.4': '서울 지하철 1호선 종각역 8번 출구에서 도보 5분',
      'home.service.section.location.5': '101, 103, 143 등 "종로2가(중)" 정류장 하차 후 도보 5분',

      'home.recruit.1': '채용안내',
      'home.recruit.2': '우리의 성장과 미래를 함께할 인재를 모집합니다.',
      'home.recruit.3': '구성원과 협동 관계를 중요시하는 사람',
      'home.recruit.4': '업무에 대한 호기심과 열정을 가진 사람',
      'home.recruit.5': '분야에 대한 전문지식을 쌓아 최고가 되고자 노력하는 사람',
      'home.recruit.6': '우리는 이렇게 일해요!',
      'home.recruit.7': '탄력 근무제',
      'home.recruit.8': '주중 오전 8시 30분 ~ 9시 30분 사이 자유롭게 출근,',
      'home.recruit.9': '하루 8시간만 근무하면 칼퇴근 가능!',
      'home.recruit.10': '최신 개발 장비 지원',
      'home.recruit.11': '개발하는데 불편하지 않도록,',
      'home.recruit.12': '애플 iMac + 듀얼 모니터를 사용해요!',
      'home.recruit.13': '자유로운 연차 사용',
      'home.recruit.14': '급한일이 있을때, 어디론가 떠나고 싶을때,',
      'home.recruit.15': '조퇴/반차/연차, 모두 자유롭게 사용해요.',
      'home.recruit.16': '불필요한 회식은 그만',
      'home.recruit.17': '구성원들이 원할때 회식을 해요.',
      'home.recruit.18': '누구도 음주를 강요하지 않아요!',
      'home.recruit.19': '자유로운 출근 복장',
      'home.recruit.20': '출근 복장에 제한없이 자유롭게 입어요.',
      'home.recruit.21': '비올때 슬리퍼, 더울때 반바지도 상관없어요!',
      'home.recruit.22': '구성원들의 성장을 위해 함께 합니다.',
      'home.recruit.23': '도서, 교육, 세미나 비용 지원',
      'home.recruit.24': '자기 계발을 위한 도서 구매 비용을 무제한 지원!!',
      'home.recruit.25': '온/오프라인 교육, 세미나 비용 지원도 OK!!',
      'home.recruit.26': '야근 수당/심야 택시비 지원',
      'home.recruit.27': '야근은 별로 없지만 야근 시 야근수당을 드려요.',
      'home.recruit.28': '밤 11시 이후 퇴근 시 택시비를 지원해요!',
      'home.recruit.29': '재택 근무 지원',
      'home.recruit.30': '사고, 질병 등으로 출퇴근이 불가능 할때,',
      'home.recruit.31': '재택 근무가 가능해요!',
      'home.recruit.32': '생일 축하금 + 조기 퇴근',
      'home.recruit.33': '1년에 한번뿐인 생일을 기념하기 위해,',
      'home.recruit.34': '백화점 상품권을 드려요. 조기 퇴근은 덤!',
      'home.recruit.35': '명절 선물 제공',
      'home.recruit.36': '가족과 함께하는 즐거운 명절,',
      'home.recruit.37': '설, 추석에는 백화점 상품권을 드려요!',
      'home.recruit.38': '비혼선언 축하금 지원',
      'home.recruit.39': '비혼을 선언한 구성원에게도 결혼하는 직원과',
      'home.recruit.40': '동일하게 경조금과 경조휴가를 드려요!',
      'home.recruit.41': '경조사 지원',
      'home.recruit.42': '기쁜 일, 슬픈 일 모두 챙겨드려요.',
      'home.recruit.43': '경조사비, 화환 또는 근조화환을 지원해요.',
      'home.recruit.44': '해외 워크샵',
      'home.recruit.45': '열심히 일한 우리 모두에게 주는 선물!',
      'home.recruit.46': '1년 동안 고생한 구성원들과 함께 해외 워크샵을 가요!',
      'home.recruit.47': '현재 채용중인 포지션',
      'home.recruit.48': '트래포트에서 여러분을 기다려요!',
      'home.recruit.49': '채용중',
      'home.recruit.50': '프론트엔드 개발자 - Software Developer(Frontend) - 2명',
      'home.recruit.51': 'HTML/CSS/JavaScript(TypeScript)를 사용한 개발에 능숙한 사람',
      'home.recruit.52': 'Angular Framework으로 SPA, SSR 개발 경험이 있는 사람, React 경험자',
      'home.recruit.53': 'REST API를 이용한 클라이언트 개발 경험이 있는 사람',
      'home.recruit.54': '분산 버전 관리 시스템(Git) 이용에 능숙한 사람',
      'home.recruit.55': '채용중',
      'home.recruit.56': '서버 개발자 - Software Developer(Backend) - 2명',
      'home.recruit.57': 'Spring Boot 개발 또는 구축 경험이 있는 사람',
      'home.recruit.58': 'Spring Webflux + Java, Kotlin을 이용한 백엔드 구축 경험이 있는 사람',
      'home.recruit.59': 'Python + Django Web Framework 구축 경험이 있는 사람',
      'home.recruit.60': 'AWS/DevOps 구축 경험이 있는 사람',
      'home.recruit.61': '트래포트와 함께 할 여러분을 기다립니다.',
      'home.recruit.62': '트래포트 입사 지원하기',

      'home.footer.1': '트래포트(주)',
      'home.footer.2': '대표자명 : 안경열',
      'home.footer.3': '사업자등록번호 : 211-88-19164',
      'home.footer.4': '사업자정보확인',
      'home.footer.5': '개인정보관리책임자 : 김영권',
      'home.footer.6': '서울 종로구 인사동5길 25 하나로빌딩 809호',
      'home.footer.7': '주소',
      'home.footer.8': '전화',
      'home.footer.9': 'Copyright © TRAPORT, All Rights Reserved',

      'modal.recruit.1': '입사 지원하기',
      'modal.recruit.2': '이름',
      'modal.recruit.3': '생년월일',
      'modal.recruit.4': '성별',
      'modal.recruit.5': '성별 선택',
      'modal.recruit.6': '남자',
      'modal.recruit.7': '여자',
      'modal.recruit.8': '이메일 주소',
      'modal.recruit.9': '이메일 주소 입력',
      'modal.recruit.10': '연락처',
      'modal.recruit.11': '휴대폰 번호 입력(숫자만 입력)',
      'modal.recruit.12': '포트폴리오',
      'modal.recruit.13': '포트폴리오 주소 입력',
      'modal.recruit.14': '최종 학력',
      'modal.recruit.15': '최종 학력 선택',
      'modal.recruit.16': '고등학교 졸업',
      'modal.recruit.17': '대학교 재학중',
      'modal.recruit.18': '대학교 졸업(전문 학사)',
      'modal.recruit.19': '대학교 졸업(학사 이상)',
      'modal.recruit.20': '경력 기간 선택',
      'modal.recruit.lessyear': '년 미만',
      'modal.recruit.moreyear': '년 이상',
      'modal.recruit.23': '지원 포지션',
      'modal.recruit.24': '지원 포지션 선택',
      'modal.recruit.25': '프론트엔드(Frontend)',
      'modal.recruit.26': '백엔드(Backend)',
      'modal.recruit.27': '필수',
      'modal.recruit.28': '개인 정보 수집/이용',
      'modal.recruit.29': '에 동의합니다.',
      'modal.recruit.30': '내용보기',
      'modal.recruit.31': '한글 이름 입력',
      'modal.recruit.32': '생년월일 8자리 입력(숫자만 입력)',
      'modal.recruit.33': '경력 기간',
      'modal.recruit.34': '접수된 내용은 채용 절차 진행 외의 용도로 사용되지 않습니다.',
      'modal.recruit.35': '입사 지원 접수하기',
      'modal.recruit.36': '빈칸을 모두 입력해주세요',
      'modal.recruit.37': '항목에 동의해주세요',
      'modal.recruit.38': '이메일을 형식에 맞춰주세요',

      'recruit.notice.1': '1. 수집 목적',
      'recruit.notice.2': '- 채용 접수',
      'recruit.notice.3': '2. 수집 항목',
      'recruit.notice.4': '- 이름, 생년월일, 성별, 이메일 주소, 연락처, 포트폴리오, 최종 학력, 경력 기간, 지원 포지션',
      'recruit.notice.5': '3. 보유 기간',
      'recruit.notice.6': '- 채용 종료 시까지',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ko', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
