import { useTranslation } from "react-i18next";

export default function LocationSection() {
  const { t } = useTranslation();

  return (
    <section id="location">
      <div className="inside">
        <h2 className="section-title1 align-c" data-aos="fade-up">{t("home.service.section.location.1")}</h2>
        <h3 className="section-subtitle1 align-c" data-aos="fade-up">{t("home.service.section.location.2")}</h3>
        <div className="contact-info">
          <div className="item" data-aos="fade-up">
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.5 21.75h-.127C4.635 20.902 2.543 8.467 2.25 4.672A2.25 2.25 0 0 1 4.32 2.25h4.133a1.5 1.5 0 0 1 1.395.945L10.988 6a1.5 1.5 0 0 1-.33 1.62L9.06 9.232a7.027 7.027 0 0 0 5.685 5.7l1.628-1.612A1.499 1.499 0 0 1 18 13.012l2.828 1.133a1.5 1.5 0 0 1 .922 1.395v3.96a2.25 2.25 0 0 1-2.25 2.25Zm-15-18a.75.75 0 0 0-.75.75v.06C4.095 9 6.308 19.5 19.455 20.25a.749.749 0 0 0 .795-.705V15.54l-2.827-1.132-2.153 2.137-.36-.045C8.385 15.682 7.5 9.158 7.5 9.09l-.045-.36 2.13-2.153L8.46 3.75H4.5Z"></path>
            </svg>
            <p><a href="tel:0269679918">02-6967-9918</a></p>
          </div>
          <div className="item" data-aos="fade-up">
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 6.75h-2.25v-4.5H5.25v4.5H3a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h2.25v4.5h13.5v-4.5H21a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5Zm-14.25-3h10.5v3H6.75v-3Zm10.5 16.5H6.75v-7.5h10.5v7.5Zm3.75-4.5h-2.25v-4.5H5.25v4.5H3v-7.5h18v7.5Z"></path>
            </svg>
            <p>02-2178-9379</p>
          </div>
          <div className="item" data-aos="fade-up">
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 4.5H3A1.5 1.5 0 0 0 1.5 6v12A1.5 1.5 0 0 0 3 19.5h18a1.5 1.5 0 0 0 1.5-1.5V6A1.5 1.5 0 0 0 21 4.5ZM19.35 6 12 11.085 4.65 6h14.7ZM3 18V6.683l8.572 5.932a.75.75 0 0 0 .856 0L21 6.682V18H3Z"></path>
            </svg>
            <p><a href="mailto:service@traport.com">service@traport.com</a></p>
          </div>
        </div>
      </div>
      <div className="inside">
        <div id="map" data-aos="fade-up"></div>
        <ul className="map-info">
          <li data-aos="fade-up">
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 1.5h-9A1.501 1.501 0 0 0 10.5 3v7.5H3A1.502 1.502 0 0 0 1.5 12v10.5h21V3A1.502 1.502 0 0 0 21 1.5ZM6.75 21v-5.25h3V21h-3ZM21 21h-9.75v-6a.75.75 0 0 0-.75-.75H6a.75.75 0 0 0-.75.75v6H3v-9h9V3h9v18Z"></path>
              <path d="M15 6h-1.5v1.5H15V6Z"></path>
              <path d="M19.5 6H18v1.5h1.5V6Z"></path>
              <path d="M15 10.5h-1.5V12H15v-1.5Z"></path>
              <path d="M19.5 10.5H18V12h1.5v-1.5Z"></path>
              <path d="M15 15h-1.5v1.5H15V15Z"></path>
              <path d="M19.5 15H18v1.5h1.5V15Z"></path>
            </svg>
            <span>{t("home.service.section.location.3")}</span>
          </li>
          <li data-aos="fade-up">
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.75 2.25h-7.5A3.754 3.754 0 0 0 4.5 6v9a3.743 3.743 0 0 0 2.686 3.578L5.827 21.75h1.632l1.286-3h6.51l1.286 3h1.632l-1.36-3.172A3.743 3.743 0 0 0 19.5 15V6a3.754 3.754 0 0 0-3.75-3.75Zm-7.5 1.5h7.5a2.246 2.246 0 0 1 2.112 1.5H6.138a2.246 2.246 0 0 1 2.112-1.5ZM18 14.25h-2.25v1.5h2.112a2.246 2.246 0 0 1-2.112 1.5h-7.5a2.246 2.246 0 0 1-2.112-1.5H8.25v-1.5H6v-1.5h12v1.5Zm0-3H6v-4.5h12v4.5Z"></path>
            </svg>
            <span>{t("home.service.section.location.4")}</span>
          </li>
          <li data-aos="fade-up">
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.75 8.25h-1.5v3h1.5v-3Z"></path>
              <path d="M3.75 8.25h-1.5v3h1.5v-3Z"></path>
              <path d="M16.5 15H15v1.5h1.5V15Z"></path>
              <path d="M9 15H7.5v1.5H9V15Z"></path>
              <path d="M15.75 3h-7.5A3.754 3.754 0 0 0 4.5 6.75v10.5a1.502 1.502 0 0 0 1.5 1.5V21h1.5v-2.25h9V21H18v-2.25a1.502 1.502 0 0 0 1.5-1.5V6.75A3.755 3.755 0 0 0 15.75 3ZM18 7.5V12H6V7.5h12Zm-9.75-3h7.5A2.246 2.246 0 0 1 17.862 6H6.138A2.246 2.246 0 0 1 8.25 4.5ZM6 17.25V13.5h12l.002 3.75H6Z"></path>
            </svg>
            <span>{t("home.service.section.location.5")}</span>
          </li>
        </ul>
      </div>
    </section>
  );
}
