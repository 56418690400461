import { useTranslation } from 'react-i18next';

export const PcHeader = () => {
  const { t } = useTranslation();

  return (
    <header id="header" className="is-active">
      <nav className="main-nav">
        <div className="nav-item"><a href="#introduce">{t('home.header.introduce.company')}</a></div>
        <div className="nav-item"><a href="#service">{t('home.header.introduce.business')}</a></div>
        <div className="nav-item"><a href="#recruit">{t('home.header.introduce.hire')}</a></div>
        <div className="nav-item"><a href="#location">{t('home.header.introduce.place')}</a></div>
      </nav>
    </header>
  );
}