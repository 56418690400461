import { useTranslation } from "react-i18next";

export default function SingleApi() {
  const { t } = useTranslation();
  
  return (
    <div className="api-info">
      <h4 className="section-title2" data-aos="fade-up">Single API</h4>
      <h5 className="section-subtitle2" data-aos="fade-up">{t('home.service.section.single.api.1')}</h5>
      <div className="api-detail-info">
        <div className="api-company">
          <div className="circle" data-aos="flip-up">Third-Party<br />Supplier</div>
          <div className="circle" data-aos="flip-up">PMS</div>
          <div className="circle" data-aos="flip-up">CMS</div>
        </div>
        <div className="arrow-list">
          <div className="arrow a1" data-aos="fade-down-right"></div>
          <div className="arrow a2" data-aos="fade-right"></div>
          <div className="arrow a3" data-aos="fade-up-right"></div>
        </div>
        <div className="my">
          <div className="circle" data-aos="zoom-in-up">{t('home.service.section.single.api.2')}<br /><span>{t('home.service.section.single.api.3')}<br />{t('home.service.section.single.api.4')}<br />VCC</span></div>
        </div>
        <div className="transfer" data-aos="fade-up">
          <div className="arrow a1"></div>
          <div className="text">Single API</div>
          <div className="arrow a2"></div>
        </div>
        <div className="customer">
          <div className="circle" data-aos="zoom-in-up">{t('home.service.section.single.api.5')}</div>
        </div>
      </div>
      <div className="api-detail-info2">
        <div className="api-company">
          <div className="circle" data-aos="flip-up">Third-Party<br />Supplier</div>
          <div className="circle" data-aos="flip-up">PMS</div>
          <div className="circle" data-aos="flip-up">CMS</div>
        </div>
        <div className="arrow-list">
          <div className="arrow a1" data-aos="fade-up"></div>
          <div className="arrow a2" data-aos="fade-up"></div>
          <div className="arrow a3" data-aos="fade-up"></div>
        </div>
        <div className="my">
          <div className="circle" data-aos="zoom-in-up">{t('home.service.section.single.api.2')}<br /><span>{t('home.service.section.single.api.3')}<br />{t('home.service.section.single.api.4')}<br />VCC</span></div>
        </div>
        <div className="transfer" data-aos="fade-up">
          <div className="arrow a1"></div>
          <div className="text">Single API</div>
          <div className="arrow a2"></div>
        </div>
        <div className="customer">
          <div className="circle" data-aos="zoom-in-up">{t('home.service.section.single.api.5')}</div>
        </div>
      </div>
      <ul className="detail-text">
        <li data-aos="fade-up">
          <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 17.998 3 11.248l1.06-1.06 5.69 5.688L19.94 5.688 21 6.748 9.75 17.998Z"></path>
          </svg>
          <span>{t('home.service.section.single.api.6')}</span>
        </li>
        <li data-aos="fade-up">
          <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 17.998 3 11.248l1.06-1.06 5.69 5.688L19.94 5.688 21 6.748 9.75 17.998Z"></path>
          </svg>
          <span>{t('home.service.section.single.api.7')}</span>
        </li>
        <li data-aos="fade-up">
          <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 17.998 3 11.248l1.06-1.06 5.69 5.688L19.94 5.688 21 6.748 9.75 17.998Z"></path>
          </svg>
          <span>{t('home.service.section.single.api.8')}</span>
        </li>
      </ul>
    </div>
  );
}