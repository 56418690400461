import { useTranslation } from "react-i18next";

export default function IntroduceSection() {
  const { t } = useTranslation();
  
  return (
    <section id="introduce">
        <div className="inside">
          <h2 className="section-title1 align-c" data-aos="fade-up">{t("home.introduce.section.company")}</h2>
          <h3 className="section-subtitle2 align-c" data-aos="fade-up">We are different</h3>
          <div className="intro-service">
            <h4 className="section-title2" data-aos="fade-up">Since 2008</h4>
            <h5 className="section-subtitle2" data-aos="fade-up">{t("home.introduce.section.introduce.1")}</h5>
            <div className="service-detail-info">
              <div className="item" data-aos="fade-up">
                <p><span>"</span>{t("home.introduce.section.introduce.2")}<span>"</span></p>
                <p>{t("home.introduce.section.introduce.3")}</p>
              </div>
              <div className="item" data-aos="fade-up">
                <p><span>"</span>{t("home.introduce.section.introduce.4")}<span>"</span></p>
                <p>{t("home.introduce.section.introduce.5")}</p>
              </div>
              <div className="item" data-aos="fade-up">
                <p><span>"</span>{t("home.introduce.section.introduce.6")}<span>"</span></p>
                <p>{t("home.introduce.section.introduce.7")}</p>
              </div>
            </div>
          </div>
          <div className="intro-history">
            <h4 className="section-title2" data-aos="fade-up">History</h4>
            <h5 className="section-subtitle2" data-aos="fade-up">{t("home.introduce.section.history.1")}</h5>
            <div className="history-detail-info">
              <h6 className="title" data-aos="fade-up">2015 ~ 2022</h6>
              <div className="history-list">
                <dl data-aos="fade-up">
                  <dt>2022</dt>
                  <dd>{t("home.introduce.section.history.2")}</dd>
                  <dd>{t("home.introduce.section.history.3")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2021</dt>
                  <dd>{t("home.introduce.section.history.4")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2018</dt>
                  <dd>{t("home.introduce.section.history.5")}</dd>
                  <dd>{t("home.introduce.section.history.6")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2017</dt>
                  <dd>{t("home.introduce.section.history.7")}</dd>
                  <dd>{t("home.introduce.section.history.8")}</dd>
                  <dd>{t("home.introduce.section.history.9")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2016</dt>
                  <dd>{t("home.introduce.section.history.10")}</dd>
                  <dd>{t("home.introduce.section.history.11")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2015</dt>
                  <dd>{t("home.introduce.section.history.12")}</dd>
                  <dd>{t("home.introduce.section.history.13")}</dd>
                </dl>
              </div>
            </div>
            <div className="history-detail-info">
              <h6 className="title" data-aos="fade-up">2009 ~ 2014</h6>
              <div className="history-list">
                <dl data-aos="fade-up">
                  <dt>2014</dt>
                  <dd>{t("home.introduce.section.history.14")}</dd>
                  <dd>{t("home.introduce.section.history.15")}</dd>
                  <dd>{t("home.introduce.section.history.16")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2013</dt>
                  <dd>{t("home.introduce.section.history.17")}</dd>
                  <dd>{t("home.introduce.section.history.18")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2012</dt>
                  <dd>{t("home.introduce.section.history.19")}</dd>
                  <dd>{t("home.introduce.section.history.20")}</dd>
                  <dd>{t("home.introduce.section.history.21")}</dd>
                  <dd>{t("home.introduce.section.history.22")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2011</dt>
                  <dd>{t("home.introduce.section.history.23")}</dd>
                  <dd>{t("home.introduce.section.history.24")}</dd>
                  <dd>{t("home.introduce.section.history.25")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2010</dt>
                  <dd>{t("home.introduce.section.history.26")}</dd>
                  <dd>{t("home.introduce.section.history.27")}</dd>
                  <dd>{t("home.introduce.section.history.28")}</dd>
                  <dd>{t("home.introduce.section.history.29")}</dd>
                </dl>
                <dl data-aos="fade-up">
                  <dt>2009</dt>
                  <dd>{t("home.introduce.section.history.30")}</dd>
                  <dd>{t("home.introduce.section.history.31")}</dd>
                  <dd>{t("home.introduce.section.history.32")}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}