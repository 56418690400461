import { useTranslation } from "react-i18next";

export default function OtaService() {
  const { t } = useTranslation();

  return (
    <div className="ota-info">
      <h4 className="section-title2" data-aos="fade-up">Rebook Process</h4>
      <h5 className="section-subtitle2" data-aos="fade-up">{t("home.service.section.ota.1")}</h5>
      <div className="ota-detail-info">
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5 17.25H12v-1.5a2.253 2.253 0 0 0-2.25-2.25h-3a2.253 2.253 0 0 0-2.25 2.25v1.5H3v-1.5A3.755 3.755 0 0 1 6.75 12h3a3.755 3.755 0 0 1 3.75 3.75v1.5Z"></path>
              <path d="M8.25 4.5a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5Zm0-1.5a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"></path>
              <path d="M22.5 19.5h-21V21h21v-1.5Z"></path>
              <path d="M16.5 3v1.5h3.44L15 9.44l1.06 1.06L21 5.56V9h1.5V3h-6Z"></path>
            </svg>
          </p>
          <p>STEP.1</p>
          <p>{t("home.service.section.ota.2")}</p>
        </div>
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 19.942 16.058 18 15 19.058l3 3 5.25-5.25-1.058-1.058L18 19.942Z"></path>
              <path d="M12.75 17.25h-1.5v1.5h1.5v-1.5Z"></path>
              <path d="M8.25 17.25h-1.5v1.5h1.5v-1.5Z"></path>
              <path d="M17.25 13.5h-1.5V15h1.5v-1.5Z"></path>
              <path d="M12.75 13.5h-1.5V15h1.5v-1.5Z"></path>
              <path d="M8.25 13.5h-1.5V15h1.5v-1.5Z"></path>
              <path d="M17.25 9.75h-1.5v1.5h1.5v-1.5Z"></path>
              <path d="M12.75 9.75h-1.5v1.5h1.5v-1.5Z"></path>
              <path d="M8.25 9.75h-1.5v1.5h1.5v-1.5Z"></path>
              <path d="M17.25 5.25H6.75V7.5h10.5V5.25Z"></path>
              <path d="M12.75 22.5H4.503A1.505 1.505 0 0 1 3 20.996V2.997A1.499 1.499 0 0 1 4.497 1.5h15.006A1.499 1.499 0 0 1 21 2.997V13.5h-1.5V3h-15v18h8.25v1.5Z"></path>
            </svg>
          </p>
          <p>STEP.2</p>
          <p>{t("home.service.section.ota.3")}</p>
        </div>
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 18v1.5h1.844A4.456 4.456 0 0 1 16.5 21a4.504 4.504 0 0 1-4.5-4.5h-1.5A5.988 5.988 0 0 0 21 20.449V22.5h1.5V18H18Z"></path>
              <path d="M16.5 10.5a6.032 6.032 0 0 0-4.5 2.051V10.5h-1.5V15H15v-1.5h-1.844A4.456 4.456 0 0 1 16.5 12a4.504 4.504 0 0 1 4.5 4.5h1.5a6.007 6.007 0 0 0-6-6Z"></path>
              <path d="M9 21H4.5v-3H6v-1.5H4.5v-3.75H6v-1.5H4.5V7.5H6V6H4.5V3H18v6h1.5V3A1.5 1.5 0 0 0 18 1.5H4.5A1.5 1.5 0 0 0 3 3v3H1.5v1.5H3v3.75H1.5v1.5H3v3.75H1.5V18H3v3a1.5 1.5 0 0 0 1.5 1.5H9V21Z"></path>
            </svg>
          </p>
          <p>STEP.3</p>
          <p>{t("home.service.section.ota.4")}</p>
        </div>
        <div className="item" data-aos="flip-down">
          <p>
            <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.75 11.25c1.114 0 1.916 1.123 2.765 2.31.984 1.378 2.099 2.94 3.985 2.94 4.253 0 5.835-8.092 6-9l-1.476-.269C20.663 9.212 19.045 15 16.5 15c-1.114 0-1.916-1.123-2.765-2.31-.984-1.378-2.099-2.94-3.985-2.94-3.14 0-5.584 5.553-6.75 8.821V1.5H1.5V21A1.502 1.502 0 0 0 3 22.5h19.5V21H3.783c1.133-3.857 3.69-9.75 5.967-9.75Z"></path>
            </svg>
          </p>
          <p>RESULT</p>
          <p>{t("home.service.section.ota.5")}</p>
        </div>
      </div>
      <ul className="detail-text">
        <li data-aos="fade-up">
          <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 17.998 3 11.248l1.06-1.06 5.69 5.688L19.94 5.688 21 6.748 9.75 17.998Z"></path>
          </svg>
          <span>{t("home.service.section.ota.6")}</span>
        </li>
        <li data-aos="fade-up">
          <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 17.998 3 11.248l1.06-1.06 5.69 5.688L19.94 5.688 21 6.748 9.75 17.998Z"></path>
          </svg>
          <span>{t("home.service.section.ota.7")}</span>
        </li>
        <li data-aos="fade-up">
          <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 17.998 3 11.248l1.06-1.06 5.69 5.688L19.94 5.688 21 6.748 9.75 17.998Z"></path>
          </svg>
          <span>{t("home.service.section.ota.8")}</span>
        </li>
      </ul>
    </div>
  );
}