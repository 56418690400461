import { useTranslation } from "react-i18next";
import CtmInfo from "./services/ctm-info";
import OtaService from "./services/ota-service";
import SingleApi from "./services/single-api";

export default function ServiceSection() {
  const { t } = useTranslation();
  
  return (
    <section id="service">
      <div className="inside">
        <h2 className="section-title1 align-c" data-aos="fade-up">{t('home.service.section.business')}</h2>
        <h3 className="section-subtitle1 align-c" data-aos="fade-up">For the better Future</h3>
        {/* <!-- * ota --> */}
        <OtaService></OtaService>
        {/* <!-- * single api --> */}
        <SingleApi></SingleApi>
        {/* <!-- * ctm --> */}
        <CtmInfo></CtmInfo>
      </div>
    </section>
  );
}