import { useTranslation } from "react-i18next";

export default function RecruitSection() {
  const { t } = useTranslation();
  
  return (
    <section id="recruit">
      <div className="inside">
        <h2 className="section-title1 align-c" data-aos="fade-in">{t('home.recruit.1')}</h2>
        <h3 className="section-subtitle1 align-c" data-aos="fade-in">{t('home.recruit.2')}</h3>
        <div className="recruit-info">
          <div className="item" data-aos="flip-down">
            <p>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
                <path d="M12 16.5a3 3 0 1 0 0-5.999 3 3 0 0 0 0 5.999Zm0-4.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"></path>
                <path d="M13.5 4.5h-3V6h3V4.5Z"></path>
                <path d="M18 1.5H6A1.502 1.502 0 0 0 4.5 3v18A1.502 1.502 0 0 0 6 22.5h12a1.502 1.502 0 0 0 1.5-1.5V3A1.502 1.502 0 0 0 18 1.5ZM15 21H9v-1.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75V21Zm1.5 0v-1.5a2.25 2.25 0 0 0-2.25-2.25h-4.5A2.25 2.25 0 0 0 7.5 19.5V21H6V3h12v18h-1.5Z"></path>
              </svg>
            </p>
            <p>Human Relationship</p>
            <p>{t('home.recruit.3')}</p>
          </div>
          <div className="item" data-aos="flip-down">
            <p>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
                <path d="M18.624 12.727c-.204-.485-.436-1.035-.662-1.714-.593-1.776 1.3-3.714 1.318-3.733l-1.06-1.06c-.106.105-2.568 2.608-1.681 5.267.244.734.488 1.312.703 1.823.5.989.76 2.082.758 3.19a4.681 4.681 0 0 1-3.142 3.97 6.389 6.389 0 0 0-1.578-6l-.783-.784-.436 1.018c-1.377 3.213-3.016 4.616-3.98 5.201A4.383 4.383 0 0 1 6 16.5a7.218 7.218 0 0 1 .697-2.722A8.5 8.5 0 0 0 7.5 10.5V9.166c.655.27 1.5.978 1.5 2.834v1.953l1.307-1.451c2.334-2.59 1.847-5.676.905-7.731A3.364 3.364 0 0 1 13.5 8.25H15C15 4.097 11.566 3 9.75 3h-1.5l.9 1.199c.103.14 2.146 2.946 1.014 5.766A3.707 3.707 0 0 0 6.75 7.5H6v3a7.218 7.218 0 0 1-.697 2.722A8.5 8.5 0 0 0 4.5 16.5c0 2.886 2.867 6 7.5 6s7.5-3.114 7.5-6a8.534 8.534 0 0 0-.876-3.773Zm-8.998 7.918a12.375 12.375 0 0 0 3.275-4.2 4.578 4.578 0 0 1 .193 4.48A8.486 8.486 0 0 1 12 21a7.747 7.747 0 0 1-2.374-.355Z"></path>
              </svg>
            </p>
            <p>Passion</p>
            <p>{t('home.recruit.4')}</p>
          </div>
          <div className="item" data-aos="flip-down">
            <p>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
                <path d="M19.5 22.5H18v-2.25a3.755 3.755 0 0 0-3.75-3.75h-4.5A3.755 3.755 0 0 0 6 20.25v2.25H4.5v-2.25A5.256 5.256 0 0 1 9.75 15h4.5a5.256 5.256 0 0 1 5.25 5.25v2.25Z"></path>
                <path d="M3.75 4.5a.75.75 0 0 0-.75.75V12h1.5V5.25a.75.75 0 0 0-.75-.75Z"></path>
                <path d="M3 1.5V3h3.75v5.25a5.25 5.25 0 0 0 10.5 0V3H21V1.5H3ZM8.25 3h7.5v2.25h-7.5V3ZM12 12a3.75 3.75 0 0 1-3.75-3.75v-1.5h7.5v1.5A3.75 3.75 0 0 1 12 12Z"></path>
              </svg>
            </p>
            <p>Self Improvement</p>
            <p>{t('home.recruit.5')}</p>
          </div>
        </div>
        <div className="life">
          <h4 className="section-title2" data-aos="fade-up">Our Life</h4>
          <h5 className="section-subtitle2" data-aos="fade-up">{t('home.recruit.6')}</h5>
          <div className="life-info">
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22.5a10.5 10.5 0 1 1 0-21 10.5 10.5 0 0 1 0 21ZM12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18Z"></path>
                  <path d="m15.443 16.5-4.193-4.193V5.25h1.5v6.435l3.75 3.758-1.057 1.057Z"></path>
                </svg>
                <span>{t('home.recruit.7')}</span>
              </dt>
              <dd>{t('home.recruit.8')}<br />{t('home.recruit.9')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 18h-15A1.502 1.502 0 0 1 3 16.5V6a1.502 1.502 0 0 1 1.5-1.5h15A1.502 1.502 0 0 1 21 6v10.5a1.502 1.502 0 0 1-1.5 1.5ZM4.5 6v10.5h15V6h-15Z"></path>
                  <path d="M22.5 19.5h-21V21h21v-1.5Z"></path>
                </svg>
                <span>{t('home.recruit.10')}</span>
              </dt>
              <dd>{t('home.recruit.11')}<br />{t('home.recruit.12')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 4.5A1.5 1.5 0 0 0 19.5 3h-3V1.5H15V3H9V1.5H7.5V3h-3A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h3v-1.5h-3v-15h3V6H9V4.5h6V6h1.5V4.5h3V9H21V4.5Z"></path>
                  <path d="m15.75 11.25 1.912 3.704 4.088.593-3 2.883.75 4.07-3.75-1.922L12 22.5l.75-4.07-3-2.883 4.2-.594 1.8-3.703Z"></path>
                </svg>
                <span>{t('home.recruit.13')}</span>
              </dt>
              <dd>{t('home.recruit.14')}<br />{t('home.recruit.15')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.25 1.5h-1.5V9h1.5V1.5Z"></path>
                  <path d="M10.5 8.25a3 3 0 0 1-6 0V1.5H3v6.75a4.5 4.5 0 0 0 3.75 4.432V22.5h1.5v-9.818A4.5 4.5 0 0 0 12 8.25V1.5h-1.5v6.75Z"></path>
                  <path d="M16.5 1.503h-.75v21h1.5v-7.5h2.25a1.5 1.5 0 0 0 1.5-1.5v-7.5a4.334 4.334 0 0 0-4.5-4.5Zm3 12h-2.25V3.071c2.16.42 2.25 2.655 2.25 2.932v7.5Z"></path>
                </svg>
                <span>{t('home.recruit.16')}</span>
              </dt>
              <dd>{t('home.recruit.17')}<br />{t('home.recruit.18')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 18.003a6 6 0 0 0 5.137-2.917l-1.282-.75a4.499 4.499 0 0 1-7.71 0l-1.283.75A6 6 0 0 0 12 18.003Z"></path>
                  <path d="M12 1.5a10.5 10.5 0 1 0 0 21 10.5 10.5 0 0 0 0-21ZM12 3a9 9 0 0 1 8.168 5.25H18.75A.75.75 0 0 0 18 7.5H6a.75.75 0 0 0-.75.75H3.833A9 9 0 0 1 12 3Zm0 18a9 9 0 0 1-9-9 8.9 8.9 0 0 1 .3-2.25h1.95v1.5a1.5 1.5 0 0 0 1.5 1.5h2.483a1.5 1.5 0 0 0 1.5-1.252L11.123 9h1.755l.412 2.498a1.5 1.5 0 0 0 1.5 1.252h2.46a1.5 1.5 0 0 0 1.5-1.5v-1.5h1.95c.196.734.297 1.49.3 2.25a9 9 0 0 1-9 9Z"></path>
                </svg>
                <span>{t('home.recruit.19')}</span>
              </dt>
              <dd>{t('home.recruit.20')}<br />{t('home.recruit.21')}</dd>
            </dl>
          </div>
        </div>
        <div className="benefit">
          <h4 className="section-title2" data-aos="fade-up">Benefit</h4>
          <h5 className="section-subtitle2" data-aos="fade-up">{t('home.recruit.22')}</h5>
          <div className="benefit-info">
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m20.48 4.58 2.02-2.02-1.06-1.06-2.02 2.02-.82-.82a1.45 1.45 0 0 0-2.1 0L3 16.2V21h4.8L21.3 7.5a1.45 1.45 0 0 0 0-2.1l-.82-.82ZM7.2 19.5H4.5v-2.7L17.55 3.75l2.7 2.7L7.2 19.5Z"></path>
                  <path d="m12.44 2.998-5.69 5.69 1.06 1.06 5.69-5.69-1.06-1.06Z"></path>
                </svg>
                <span>{t('home.recruit.23')}</span>
              </dt>
              <dd>{t('home.recruit.24')}<br />{t('home.recruit.25')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.127 4.06a11.306 11.306 0 0 0 8.695 13.646 8.335 8.335 0 0 1-5.981 2.542c-.104 0-.209.004-.313 0a8.32 8.32 0 0 1-2.4-16.188Zm1.108-1.81a.753.753 0 0 0-.13.012 9.822 9.822 0 0 0 1.368 19.486c.123.005.246 0 .368 0a9.805 9.805 0 0 0 8.026-4.167.757.757 0 0 0-.587-1.173 9.81 9.81 0 0 1-8.363-13.123.76.76 0 0 0-.682-1.035Z"></path>
                </svg>
                <span>{t('home.recruit.26')}</span>
              </dt>
              <dd>{t('home.recruit.27')}<br />{t('home.recruit.28')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.46 1.66a.757.757 0 0 0-.933 0L.75 10.065l.932 1.178L3 10.216V19.5A1.503 1.503 0 0 0 4.5 21h15a1.503 1.503 0 0 0 1.5-1.5v-9.277l1.318 1.027.932-1.179-10.79-8.41ZM13.5 19.5h-3v-6h3v6Zm1.5 0v-6a1.502 1.502 0 0 0-1.5-1.5h-3A1.502 1.502 0 0 0 9 13.5v6H4.5V9.046L12 3.204l7.5 5.85V19.5H15Z"></path>
                </svg>
                <span>{t('home.recruit.29')}</span>
              </dt>
              <dd>{t('home.recruit.30')}<br />{t('home.recruit.31')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 7.5h-2.071A3.365 3.365 0 0 0 12 3.53 3.365 3.365 0 0 0 6.571 7.5H4.5A1.502 1.502 0 0 0 3 9v3a1.502 1.502 0 0 0 1.5 1.5V21A1.502 1.502 0 0 0 6 22.5h12a1.502 1.502 0 0 0 1.5-1.5v-7.5A1.502 1.502 0 0 0 21 12V9a1.502 1.502 0 0 0-1.5-1.5Zm-6.75-1.874A1.875 1.875 0 1 1 14.625 7.5H12.75V5.626ZM9.375 3.75a1.877 1.877 0 0 1 1.875 1.875V7.5H9.375a1.875 1.875 0 0 1 0-3.75ZM4.5 9h6.75v3H4.5V9ZM6 13.5h5.25V21H6v-7.5ZM18 21h-5.25v-7.5H18V21Zm-5.25-9V9h6.75v3h-6.75Z"></path>
                </svg>
                <span>{t('home.recruit.32')}</span>
              </dt>
              <dd>{t('home.recruit.33')}<br />{t('home.recruit.34')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 7.5h-2.071A3.365 3.365 0 0 0 12 3.53 3.365 3.365 0 0 0 6.571 7.5H4.5A1.502 1.502 0 0 0 3 9v3a1.502 1.502 0 0 0 1.5 1.5V21A1.502 1.502 0 0 0 6 22.5h12a1.502 1.502 0 0 0 1.5-1.5v-7.5A1.502 1.502 0 0 0 21 12V9a1.502 1.502 0 0 0-1.5-1.5Zm-6.75-1.874A1.875 1.875 0 1 1 14.625 7.5H12.75V5.626ZM9.375 3.75a1.877 1.877 0 0 1 1.875 1.875V7.5H9.375a1.875 1.875 0 0 1 0-3.75ZM4.5 9h6.75v3H4.5V9ZM6 13.5h5.25V21H6v-7.5ZM18 21h-5.25v-7.5H18V21Zm-5.25-9V9h6.75v3h-6.75Z"></path>
                </svg>
                <span>{t('home.recruit.35')}</span>
              </dt>
              <dd>{t('home.recruit.36')}<br />{t('home.recruit.37')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 23.25h-3a1.504 1.504 0 0 1-1.5-1.5V16.5A1.504 1.504 0 0 1 1.5 15v-4.5a2.21 2.21 0 0 1 2.25-2.25h4.5a2.21 2.21 0 0 1 2.25 2.25V15A1.504 1.504 0 0 1 9 16.5v5.25a1.504 1.504 0 0 1-1.5 1.5ZM3.75 9.75a.708.708 0 0 0-.75.75V15h1.5v6.75h3V15H9v-4.5a.708.708 0 0 0-.75-.75h-4.5Z"></path>
                  <path d="M6 7.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6ZM6 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"></path>
                  <path d="M21.575 3.193a3.159 3.159 0 0 0-4.325-.168 3.159 3.159 0 0 0-4.325.168 3.24 3.24 0 0 0 0 4.534l4.323 4.38.002-.002.002.001 4.323-4.38a3.24 3.24 0 0 0 0-4.533Zm-1.069 3.48L17.252 9.97l-.001-.001-.002.001-3.255-3.298a1.731 1.731 0 0 1 0-2.425 1.698 1.698 0 0 1 2.368 0l.885.906.004-.004.004.004.885-.906a1.698 1.698 0 0 1 2.367 0 1.731 1.731 0 0 1 0 2.425Z"></path>
                </svg>
                <span>{t('home.recruit.38')}</span>
              </dt>
              <dd>{t('home.recruit.39')}<br />{t('home.recruit.40')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 6.75a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-4.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"></path>
                  <path d="M18 6.75a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-4.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"></path>
                  <path d="M19.5 22.5h-3A1.5 1.5 0 0 1 15 21v-5.25h1.5V21h3v-6.75H21v-4.5a.75.75 0 0 0-.75-.75h-4.815L12 15 8.565 9H3.75a.75.75 0 0 0-.75.75v4.5h1.5V21h3v-5.25H9V21a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 3 21v-5.25a1.5 1.5 0 0 1-1.5-1.5v-4.5A2.25 2.25 0 0 1 3.75 7.5h5.685L12 12l2.565-4.5h5.685a2.25 2.25 0 0 1 2.25 2.25v4.5a1.5 1.5 0 0 1-1.5 1.5V21a1.5 1.5 0 0 1-1.5 1.5Z"></path>
                </svg>
                <span>{t('home.recruit.41')}</span>
              </dt>
              <dd>{t('home.recruit.42')}<br />{t('home.recruit.43')}</dd>
            </dl>
            <dl data-aos="fade-up">
              <dt>
                <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.928 21C13.422 20.044 12 16.67 12 9v-.098l.838.558A4.845 4.845 0 0 1 15 13.5h1.5a6.343 6.343 0 0 0-2.83-5.288L12.602 7.5h1.148a5.28 5.28 0 0 1 3.15 1.05l1.4 1.05.9-1.2-1.4-1.05A6.795 6.795 0 0 0 13.75 6h-.912A5.273 5.273 0 0 1 16.5 4.5H18V3h-1.5a6.777 6.777 0 0 0-5.25 2.523A6.776 6.776 0 0 0 6 3H4.5v1.5H6A5.273 5.273 0 0 1 9.662 6H8.75A6.795 6.795 0 0 0 4.7 7.35L3.3 8.4l.9 1.2 1.4-1.05A5.28 5.28 0 0 1 8.75 7.5h1.148l-1.068.712A6.343 6.343 0 0 0 6 13.5h1.5a4.846 4.846 0 0 1 2.162-4.04l.838-.558V9c0 6.708 1.059 10.323 1.77 12H1.5v1.5h21V21h-8.572Z"></path>
                </svg>
                <span>{t('home.recruit.44')}</span>
              </dt>
              <dd>{t('home.recruit.45')}<br />{t('home.recruit.46')}</dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="recruit-now">
        <div className="inside">
          <h4 className="section-title2" data-aos="fade-up">{t('home.recruit.47')}</h4>
          <h5 className="section-subtitle2" data-aos="fade-up">{t('home.recruit.48')}</h5>
          <details className="position-item" data-aos="fade-up">
            <summary className="position-item-header">
              <span className="label type1">{t('home.recruit.49')}</span>
              <span className="subject">{t('home.recruit.50')}</span>
              <svg className="svg-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m18 9-6 7.5L6 9h12Z"></path>
              </svg>
            </summary>
            <div className="position-item-body">
              <ul>
                <li>{t('home.recruit.51')}</li>
                <li>{t('home.recruit.52')}</li>
                <li>{t('home.recruit.53')}</li>
                <li>{t('home.recruit.54')}</li>
              </ul>
            </div>
          </details>
          <details className="position-item" data-aos="fade-up">
            <summary className="position-item-header">
              <span className="label type1">{t('home.recruit.55')}</span>
              <span className="subject">{t('home.recruit.56')}</span>
              <svg className="svg-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m18 9-6 7.5L6 9h12Z"></path>
              </svg>
            </summary>
            <div className="position-item-body">
              <ul>
                <li>{t('home.recruit.57')}</li>
                <li>{t('home.recruit.58')}</li>
                <li>{t('home.recruit.59')}</li>
                <li>{t('home.recruit.60')}</li>
              </ul>
            </div>
          </details>
        </div>
      </div>
      <div className="recruit-apply" data-aos="fade-in">
        <div className="inside">
          <p className="title">{t('home.recruit.61')}</p>
          <button className="btn-recruit-apply" type="button">{t('home.recruit.62')}</button>
        </div>
        <div className="bg"></div>
      </div>
    </section>
  );
}